// Place your application-specific JavaScript functions and classes here
// This file is automatically included by javascript_include_tag :defaults

document.addEventListener("turbolinks:load", function() {
console.log('loaded');
	const spinner = {

		_spinners: [],
		startSpinner: function(spinnerName) {
			console.log(spinnerName);
							var frames = 12;
							var frame = 1;
		
							function spinnerRedraw() {
								if(frame >= frames) {
									frame = 1;
								}
			
								pos = "-"+(frame*48)+"px 0px";
								$(spinnerName).css('backgroundPosition', pos);
								frame++;
							}
							//in case one is already running
							for(i=0;i< this._spinners.length;i++)
							{ 
								clearInterval(this._spinners[i]);
							}
							this._spinners = [];
		
							//try {stopSpinner();} catch (e){}
		
							$(spinnerName).show();
							var animation = setInterval(spinnerRedraw,50);
							
							spinnerStop = function() {clearInterval(animation);};
							this._spinners.push(animation);
						},
	
		stopSpinner: function(spinnerName) {
						$(spinnerName).hide();
						spinnerStop();
						}

	}

  
  $("a[data-changepage]").on("click", function(event) {

  	console.log('clicked');

  	event.preventDefault();
  	var id = this.id;
	var current_page = parseInt($(this).attr('data-shownpage'));
	console.log(current_page);
	var url = $(this).attr('data-plainurl');

	if (id.includes('next_page')) {
	  	current_page = current_page + 1;
	  	spinner.startSpinner('#next_spin');
	  } else if (id.includes('prev_page')) {
	  	current_page = current_page - 1;
	  	spinner.startSpinner('#prev_spin');
	 };
  	$.ajax({
	  type: "get", 
	  url: url,//"<%= url_for({:action => 'show'}) %>",
	  data: 'current_page='+current_page,
	  dataType: 'script' })
    .done(function(response){
	  	spinner.stopSpinner('#next_spin');
	  	spinner.stopSpinner('#prev_spin');
	  })
	.fail(function(response){
		console.log('error in ajax call');
	})
  });
});